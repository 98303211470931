import { Box, Button, Stack } from '@mui/material';
import { kebabCase } from 'lodash';
import {
  type ChangeEventHandler,
  type FunctionComponent,
  type MouseEventHandler,
  useMemo,
  useState,
} from 'react';
import { Virtuoso } from 'react-virtuoso';

import SearchTextField from '../../../table/components/SearchTextField';

import DimensionOption from './DimensionOption';

export interface DimensionListLabels {
  searchPlaceholder: string;
  clearAll: string;
  selectAll: string;
}

export interface DimensionListProps {
  id: string;
  options: Array<{
    id: string | null;
    title?: string | null;
  }>;
  selectedFilters: Array<string | null> | undefined;
  labels: DimensionListLabels;
  handleOnSelectAll: (
    options: Array<{
      id: string | null;
      title?: string | null;
    }>,
    isAllSelected: boolean,
  ) => MouseEventHandler<HTMLButtonElement>;
  handleOnSelectFilter: (
    id: string | null,
  ) => ChangeEventHandler<HTMLInputElement>;
}

const DimensionList: FunctionComponent<DimensionListProps> = ({
  id,
  options,
  selectedFilters,
  labels,
  handleOnSelectAll,
  handleOnSelectFilter,
}) => {
  const [searchString, setSearchString] = useState('');

  const filteredOptions = useMemo(() => {
    const seen: Map<string | null, number> = new Map();

    // Using both filter and map instead of reduce as I am using the
    // filter to keep track of the dimension options that have duplicate titles
    // to then provide a subtitle of the dimension id if it has duplicates
    return options
      .filter((option) => {
        const isFound =
          !searchString ||
          option.id?.toLowerCase().includes(searchString.toLowerCase()) ||
          option.title?.toLowerCase().includes(searchString.toLowerCase());

        const seenId = kebabCase(option.title || '');
        if (isFound) {
          seen.set(seenId, (seen.get(seenId) ?? 0) + 1);
        }

        return isFound;
      })
      .map((option) => ({
        ...option,
        subtitle:
          seen.get(kebabCase(option.title || ''))! > 1 ? option.id : undefined,
        active: selectedFilters?.includes(option.id) || false,
      }));
  }, [id, searchString, selectedFilters?.length]);

  const isAllChecked = filteredOptions.every((option) =>
    selectedFilters?.includes(option.id),
  );

  return (
    <Box width="100%" px={3}>
      <Stack flexDirection="row" justifyContent="space-between" py={2}>
        <Box>
          <SearchTextField
            placeholder={labels.searchPlaceholder}
            value={searchString}
            onChange={(e) => {
              setSearchString(e.currentTarget.value);
            }}
            onClear={() => {
              setSearchString('');
            }}
            size="small"
            sx={{
              '& > div': {
                width: '300px',
              },
            }}
          />
        </Box>
        <Button
          variant="outlined"
          onClick={handleOnSelectAll(filteredOptions, isAllChecked)}
          sx={{ textTransform: 'initial', borderColor: 'transparent' }}
          disabled={filteredOptions.length === 0}
        >
          {isAllChecked ? labels.clearAll : labels.selectAll}
        </Button>
      </Stack>
      <Box height="220px">
        <Virtuoso
          style={{ maxHeight: '220px' }}
          data={filteredOptions}
          itemContent={(_, option) => {
            const optionId = `${id}:${option.id}:${option.active}`;
            return (
              <DimensionOption
                key={optionId}
                id={optionId}
                title={option.title}
                subtitle={option.subtitle}
                checked={option.active}
                handleOnChange={handleOnSelectFilter(option.id)}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default DimensionList;

import { type FilterFieldWithSku } from '@autone/openapi-replenishment';
import { type CommonTranslationFunction } from '@autone/translations';

import { type PivotDimension } from './storage/storage-atom.types';

export const getDimensionTitle = (
  dimension: PivotDimension | FilterFieldWithSku | string,
  common: CommonTranslationFunction,
  plural?: 'plural',
): string => {
  switch (dimension) {
    case 'department_id':
    case 'department':
      return common('filters.department', {
        context: plural,
      });
    case 'sub_department_id':
    case 'sub_department':
      return common('filters.sub-department', {
        context: plural,
      });
    case 'product_id':
    case 'product':
      return common('filters.product', {
        context: plural,
      });
    case 'sku_id':
    case 'sku':
      return common('filters.product-size', {
        context: plural,
      });
    case 'style_id':
    case 'style':
      return common('filters.style', {
        context: plural,
      });
    case 'material_id':
    case 'material':
      return common('filters.material', {
        context: plural,
      });
    case 'color_id':
    case 'color':
      return common('filters.color', {
        context: plural,
      });
    case 'brand_id':
    case 'brand':
      return common('filters.brand', {
        context: plural,
      });
    case 'region_id':
    case 'region':
      return common('filters.region', { context: plural });
    case 'season_id':
    case 'season':
      return common('filters.season', { context: plural });
    case 'collection_type':
      return common('filters.collection-type', { context: plural });
    case 'location_id':
    case 'location':
      return common('filters.location', { context: plural });
    case 'merchandising_flag':
      return common('filters.merchandising-flag', { context: plural });
    case 'event_id':
    case 'event':
      return common('filters.events', { context: plural });
    case 'country':
      return common('filters.country', { context: plural });
    case 'to_country_id':
    case 'to_country':
      return common('filters.to-country', { context: plural });
    case 'from_country_id':
    case 'from_country':
      return common('filters.from-country', { context: plural });
    case 'to_location_id':
    case 'to_location':
      return common('filters.to-location', { context: plural });
    case 'from_location_id':
    case 'from_location':
      return common('filters.from-location', { context: plural });
    case 'territory':
      return common('territories');
    case 'created_by':
      return common('filters.created-by');
    case 'auto_submit_rules':
      return common('filters.auto-submit-rules');
    case 'to_review_rules':
      return common('filters.to-review-rules');
    default:
      return common('na');
  }
};

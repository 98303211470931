import { capitalizeFirstLetter } from '@autone/utils';
import { Box, Checkbox, FormLabel, Typography, useTheme } from '@mui/material';
import {
  type ChangeEventHandler,
  type FunctionComponent,
  useEffect,
  useState,
} from 'react';

interface DimensionOptionProps {
  id: string | null;
  title?: string | null;
  subtitle?: string | null;
  checked: boolean;
  handleOnChange: ChangeEventHandler<HTMLInputElement>;
}

const DimensionOption: FunctionComponent<DimensionOptionProps> = ({
  id,
  title,
  subtitle,
  checked,
  handleOnChange,
}: any) => {
  const [isChecked, setIsChecked] = useState(checked);
  const theme = useTheme();

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsChecked(!isChecked);
    setTimeout(() => handleOnChange(e), 100);
  };

  return (
    <FormLabel
      htmlFor={id}
      sx={{
        display: 'flex',
        gap: 4,
        py: 0.5,
        pl: 1.5,
        pr: 0.5,
        borderRadius: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
        '&:hover': {
          bgcolor: theme.palette.action.hover,
        },
      }}
    >
      <Box>
        {capitalizeFirstLetter(title)}
        {subtitle && (
          <Typography variant="body2" fontSize="12px" color="grey.500">
            {subtitle}
          </Typography>
        )}
      </Box>
      <Checkbox id={id} name={id} checked={isChecked} onChange={onChange} />
    </FormLabel>
  );
};

export default DimensionOption;

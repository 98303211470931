import { type ChangeEventHandler, useMemo, useState } from 'react';

import { useUrlParams } from '../../../hooks';
import { type GeneralFiltersProps } from '../Filters.types';

const useGeneralFilters = ({
  filters,
  onFilterUpdate,
}: {
  filters: GeneralFiltersProps['filters'];
  onFilterUpdate?: GeneralFiltersProps['onFilterUpdate'];
}) => {
  const [currentDimension, setCurrentDimension] = useState(
    filters[0]?.dimension_id,
  );

  const [_, searchParamsBuilder] = useUrlParams();
  const selectedFilters = searchParamsBuilder.getFilters();
  const selectedCurrentDimensionFilters = selectedFilters?.[currentDimension];

  const currentDimensionFilters = useMemo(
    () => filters.find((filter) => filter.dimension_id === currentDimension),
    [currentDimension],
  )!;

  const filtersWithOptions = useMemo(
    () => filters.filter((filter) => filter.options.length > 0),
    [filters],
  );

  const handleOnFilterClick =
    (id: string | null): ChangeEventHandler<HTMLInputElement> =>
    () => {
      searchParamsBuilder.addFilters(currentDimension, [id]).push();
      onFilterUpdate?.();
    };

  const handleOnSelectAllClick =
    (options: { id: string | null }[], isAllChecked: boolean) => () => {
      let ids = options.map((option) => option.id);

      if (!isAllChecked && selectedCurrentDimensionFilters) {
        ids = ids.filter((id) => !selectedCurrentDimensionFilters.includes(id));
      }

      searchParamsBuilder.addFilters(currentDimension, ids).push();
      onFilterUpdate?.();
    };

  return {
    selectedFilters,
    currentDimension,
    setCurrentDimension,
    selectedCurrentDimensionFilters,
    currentDimensionFilters,
    filterList: filtersWithOptions,
    handleOnFilterClick,
    handleOnSelectAllClick,
  };
};

export default useGeneralFilters;

import { type History } from 'history';
import { isEmpty, omitBy, pickBy } from 'lodash';
import qs, { type ParsedQs } from 'qs';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { isKeyInType } from '../types/guards';

type ParameterValue = string | string[] | number[];

export type ParamType = {
  parameterKey: string;
  parameterValue: ParameterValue;
};

const isDefined = <T = any,>(value: T): value is T => value !== undefined;

export type SidePanelItem =
  | {
      id: string;
      type: 'product';
      name?: never;
    }
  | {
      id: string;
      type: 'location';
      name: string;
    };

export type RebalancingStrategyType =
  | 'optimise-stock'
  | 'return-to-warehouse'
  | 'high-performing-stores';

type URLParamOptions = {
  excludeKeys?: string[];
  includeKeys?: string[];
  removeExisting?: boolean;
};

export interface IUrlParamBuilder {
  addTableType(type: string): IUrlParamBuilder;
  addRedirectFromCreate(type: string): IUrlParamBuilder;
  addRebalancingStrategy(type: RebalancingStrategyType): IUrlParamBuilder;
  addBatchId(batchId: string): IUrlParamBuilder;
  addBatchName(batchName: string): IUrlParamBuilder;
  addSidePanelItem({ id, type, name }: SidePanelItem): IUrlParamBuilder;
  addFilters(dimension: string, filters: (string | null)[]): IUrlParamBuilder;
  push(options?: URLParamOptions): void;
  set(url: string, options?: URLParamOptions): string;
  getFilters(): Record<string, (string | null)[]> | null;
  removeFilter(dimension: string): IUrlParamBuilder;
  removeFilters(dimensions: string[]): IUrlParamBuilder;
  removeAllFilters(): IUrlParamBuilder;
}

const ARRAY_LIMIT = 9999;

export class UrlParamBuilder implements IUrlParamBuilder {
  searchParams: string | null = null;
  history: History | null = null;

  tableType: string | null = null;
  sidePanelItem: SidePanelItem | null = null;
  filters: Record<string, string[]> | null = null;

  redirectFromCreate: string | undefined;
  rebalancingStrategy: RebalancingStrategyType | undefined;
  batch_id: string | undefined;
  batch_name: string | undefined;

  init(searchParams: string, history: History) {
    const params = this._convertSearchParams(searchParams);
    this.searchParams = searchParams.replace(/^\?/, '');
    this.tableType = (params.tableType || null) as string | null;
    this.sidePanelItem = (params.sidePanelItem || null) as SidePanelItem | null;
    this.filters = (params.filters || null) as Record<string, string[]> | null;
    this.history = history;

    return this;
  }

  addTableType(type: string) {
    this.tableType = type;
    return this;
  }

  addRedirectFromCreate(type: string) {
    this.redirectFromCreate = type;
    return this;
  }

  addRebalancingStrategy(type: RebalancingStrategyType) {
    this.rebalancingStrategy = type;
    return this;
  }

  addBatchId(batchId: string) {
    this.batch_id = batchId;
    return this;
  }

  addBatchName(batchName: string) {
    this.batch_name = batchName;
    return this;
  }

  addSidePanelItem({ id, type, name }: SidePanelItem) {
    if (type === 'location') {
      this.sidePanelItem = { id, type, name };
    } else {
      this.sidePanelItem = { id, type };
    }

    return this;
  }

  addFilters(dimension: string, filters: string[]) {
    const urlFilters = this.filters || {};

    let updatedFilters =
      urlFilters && isKeyInType(urlFilters, dimension)
        ? urlFilters[dimension]
        : [];

    if (updatedFilters.length > 0) {
      filters.forEach((filter) => {
        const foundFilterIndex = updatedFilters.indexOf(filter);
        if (foundFilterIndex !== -1) {
          updatedFilters.splice(foundFilterIndex, 1);
        } else {
          updatedFilters.push(filter);
        }
      });
    } else {
      updatedFilters = filters;
    }

    const updatedFiltersObj = pickBy(
      {
        ...urlFilters,
        ...(updatedFilters.length > 0 ? { [dimension]: updatedFilters } : {}),
      },
      (value) => value.length > 0,
    );

    this.filters = isEmpty(updatedFiltersObj) ? null : updatedFiltersObj;

    return this;
  }

  removeFilter(dimension: string) {
    const urlFilters = this.filters;

    if (!urlFilters) {
      return this;
    }

    if (!isKeyInType(urlFilters, dimension)) {
      return this;
    }

    delete urlFilters[dimension];

    this.filters = isEmpty(urlFilters) ? null : urlFilters;

    return this;
  }

  removeFilters(dimensions: string[]) {
    const urlFilters = this.filters;

    if (!urlFilters) {
      return this;
    }

    if (dimensions.every((dimension) => !isKeyInType(urlFilters, dimension))) {
      return this;
    }

    dimensions.forEach((dimension) => {
      delete urlFilters[dimension];
    });

    this.filters = isEmpty(urlFilters) ? null : urlFilters;

    return this;
  }

  removeAllFilters() {
    this.filters = null;
    return this;
  }

  push(options?: URLParamOptions) {
    if (this.history === null) {
      return;
    }

    const params = this._build(options);
    this.history.replace({
      search: params,
    });
  }

  set(url: string, options?: URLParamOptions) {
    const params = this._build(options);
    return url.concat('?', params);
  }

  getFilters() {
    return this.filters;
  }

  getSearchParams() {
    if (this.searchParams === null) {
      return {};
    }

    return this._convertSearchParams(this.searchParams);
  }

  _build(options?: URLParamOptions) {
    const currentSearchParams = this.searchParams
      ? this._convertSearchParams(this.searchParams)
      : {};

    let updatedSearchParams = {
      ...currentSearchParams,
      ...omitBy(
        {
          redirectFromCreate: this.redirectFromCreate,
          rebalancingStrategy: this.rebalancingStrategy,
          batch_id: this.batch_id,
          batch_name: this.batch_name,
          tableType: this.tableType,
          sidePanelItem: this.sidePanelItem,
          filters: isEmpty(this.filters) ? null : this._encodeObj(this.filters),
        },
        (value) => !isDefined(value),
      ),
    };

    if (options?.excludeKeys) {
      updatedSearchParams = omitBy(updatedSearchParams, (value) =>
        options.excludeKeys?.some((key) => updatedSearchParams[key] === value),
      );
    }

    if (options?.includeKeys) {
      updatedSearchParams = pickBy(updatedSearchParams, (value) => {
        return options.includeKeys?.some(
          (key) => updatedSearchParams[key] === value,
        );
      });
    }

    this.searchParams = qs.stringify(updatedSearchParams, {
      skipNulls: true,
    });

    return this.searchParams;
  }

  _convertSearchParams(searchParams: string) {
    const obj = qs.parse(searchParams, {
      arrayLimit: ARRAY_LIMIT,
      ignoreQueryPrefix: true,
    });

    obj.filters = obj.filters
      ? this._decodeObj(obj.filters as string)
      : obj.filters;

    return obj;
  }

  _encodeObj<T>(obj: T) {
    const serializedObj = JSON.stringify(obj);
    return btoa(encodeURIComponent(serializedObj));
  }

  _decodeObj<T>(obj: string): T {
    const decodedObj = decodeURIComponent(atob(obj));
    return JSON.parse(decodedObj);
  }
}

const urlParamBuilder = new UrlParamBuilder();

const useUrlParams = (): [ParsedQs, IUrlParamBuilder] => {
  const location = useLocation();
  const history = useHistory();

  const builder = useMemo(
    () => urlParamBuilder.init(location.search, history),
    [location, history],
  );

  return [builder.getSearchParams(), builder];
};

export default useUrlParams;
